<template>
<form @submit.prevent="checkform">

  <div class="ui container">
      <div class="ui card">
        <div class="content">
          <div class="header">Joke a day</div>
        </div>
        <div class="extra content">
          <h1>{{joke}}</h1>
        </div>
        <div class="extra content">
          <button class="ui button">Get Joke</button>
        </div>
      </div>
  </div>
</form>
</template>

<script>
export default {
  name: 'App',
  components: {},
  data() {
    return {
      //----------------------- Error Message Variables  -------------------------
      joke: ''
    }
  },

  methods: {

    checkform: async function() {
      console.log("Step 1")
      this.joke = await this.getJoke();
      console.log("Step 3")
    },

    getJoke: async function() {
      var urlHost = "https://icanhazdadjoke.com"
      console.log("urlString: " + urlHost)
      // GET request using fetch with error handling
      const response = await fetch(urlHost, {
        headers: {
          Accept: "application/json",
          // Accept: "text/plain",
        },
      });
      console.log("Got Response:", response.status)
      const joke = await response.json();
      console.log("Joke: ", joke.joke)
      console.log("Step 2")

      return (joke.joke)
      // const data = await response.json();
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
